import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';

// Previews
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

// Components
import { ContactForm } from '../components/jobs/contact-form';

const Page = styled.div`
  & .content-container {
    margin: 60px auto 120px auto;
    max-width: 750px;

    & .title-container,
    & .subtitle-container {
      line-height: 170%;
    }

    b,
    strong {
      font-weight: 600;
    }

    & h2 {
      font-size: 18px;
    }

    & h1,
    & h3 {
      font-size: 22px;
      color: #000;
    }

    & .description-container {
      margin: 2em 0 0 0;

      & h3 {
        margin: 1em 0 0 0;
      }

      & ul {
        padding: 0;
        margin: 1em 0 1em 10px;

        & li {
          line-height: 150%;
          padding: 0 0 0 10px;

          ::marker {
            content: '-';
          }
        }
      }
    }

    & .apply-now {
      margin: 2em 0 0 0;

      & a {
        font-size: 22px;
        color: #000;
      }
    }
  }
`;

const Job = ({ data }) => {
  return (
    <>
      <Helmet
        title={`Jobs - ${data.prismicJob.data.job_title.text} - Treatment Studio`}
        meta={[
          {
            name: 'og:title',
            content: `Jobs - ${data.prismicJob.data.job_title.text} - Treatment Studio`,
          },
          {
            name: 'twitter:title',
            content: `Jobs - ${data.prismicJob.data.job_title.text} - Treatment Studio`,
          },
        ]}
      />

      <Page>
        <div className="content-container">
          <div
            className="title-container"
            dangerouslySetInnerHTML={{
              __html: data.prismicJob.data.job_title.html,
            }}
          />
          <div
            className="subtitle-container"
            dangerouslySetInnerHTML={{
              __html: data.prismicJob.data.job_subtitle.html,
            }}
          />

          <div
            className="description-container"
            dangerouslySetInnerHTML={{
              __html: data.prismicJob.data.job_description.html,
            }}
          />

          <div className="apply-now">
            <Link to={`/jobs/${data.prismicJob.uid}/apply/`}>Apply Now</Link>
          </div>
        </div>
      </Page>
    </>
  );
};

export default withPrismicPreview(Job);

export const query = graphql`
  query Job($uid: String!) {
    prismicJob(uid: { eq: $uid }) {
      _previewable
      uid
      data {
        job_title {
          html
          text
        }
        job_subtitle {
          html
        }
        job_description {
          html
        }
      }
    }
    prismicJobs {
      data {
        email_address
      }
    }
  }
`;
