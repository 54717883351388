import React from 'react';
import styled from 'styled-components';

const ImageWrapper = styled.div`
  width: 100%;
  position: relative;

  padding-top: ${props => props.ratio}%;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
`;

const CustomAspectRatio = ({ image, alt, width, height, setSize }) => {
  return (
    <ImageWrapper
      ratio={
        setSize === 'Full Width'
          ? (height / width) * 100
          : width > height
          ? `66.6667`
          : `142.857`
      }
    >
      <Image
        width={width}
        height={height}
        src={image}
        alt={alt}
        loading="lazy"
      />
    </ImageWrapper>
  );
};

export default CustomAspectRatio;
