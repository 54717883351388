import * as React from 'react';
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';

// Prismic
import { linkResolver } from './src/components/link-resolver/linkResolver';

import About from './src/pages/about';
import Contact from './src/pages/contact';
import Index from './src/pages/index';
import Jobs from './src/pages/jobs';

// Templates
import LongRead from './src/templates/long-read';
import Project from './src/templates/project';
import Job from './src/templates/job';

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: `treatmentstudio`,
        linkResolver,
        componentResolver: componentResolverFromMap({
          about: About,
          contact: Contact,
          homepage: Index,
          long_read: LongRead,
          jobs: Jobs,
          project: Project,
          job: Job,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
