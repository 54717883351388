import React, { useState } from 'react';
import styled from 'styled-components';

const VideoPosterContainer = styled.div`
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;
`;

const ImageWrapper = styled.div`
  width: 100%;
  position: relative;

  padding-top: ${props => props.ratio}%;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
`;

async function getVimeoThumbnail(video, width, height) {
  let response = await fetch(
    `https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${video}&width=1920&height=1080&quality=1080`
  );
  let data = await response.json();
  return data;
}

const VideoPoster = ({ image, alt, width, height, video }) => {
  const [vimeoThumbnail, setVimeoThumbnail] = useState(null);

  let video_id = /^.*((youtu.be\/|vimeo.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/,
    match = video.match(video_id);

  let loadingVimeoThumbnail = getVimeoThumbnail(match[7], width, height);
  loadingVimeoThumbnail.then(result => {
    setVimeoThumbnail(result.thumbnail_url);
  });

  if (vimeoThumbnail !== null) {
    return (
      <VideoPosterContainer>
        <ImageWrapper ratio={(height / width) * 100}>
          <Image width={width} height={height} src={vimeoThumbnail} alt={alt} />
        </ImageWrapper>
      </VideoPosterContainer>
    );
  } else {
    return null;
  }
};

export default VideoPoster;
