import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

// Styles
import '../components/styles/sal.css';
import '../components/styles/plyr.css';

// Components
import CustomAspectRatio from '../components/images/custom-aspect-ratio';
import { LongReadVideo } from '../components/long-reads/long-read-video';

const Page = styled.div`
  margin: 60px auto 0 auto;

  @media (min-width: 1500px) {
    max-width: 1200px;
  }

  @media (max-width: 1499px) {
    max-width: 1050px;
  }

  @media (max-width: 767px) {
    margin: 15px auto 0 auto;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 45px;
  grid-auto-flow: dense;

  & .half-col {
    grid-column: span 2;

    @media (max-width: 767px) {
      &:first-of-type {
        order: 12;
      }

      &:last-of-type {
        order: 1;
      }
    }
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
`;

const TitleContainer = styled.div`
  flex: 100%;
  width: 100%;
  margin: 10px 0 0 0;

  line-height: 1.25;
  text-align: center;

  & h1 {
    font-size: 28px;
    font-weight: 600;
  }

  h2 {
    font-size: 18px;
  }
`;

const TextContainer = styled.div`
  flex: 100%;
  width: 100%;

  margin: 1em 0 0 0;
  text-align: center;

  & p {
    margin: 0;
  }

  & strong {
    font-weight: 600;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  position: relative;

  & div {
    width: 100%;
  }

  & .plyr__video-embed {
    padding-bottom: ${props => props.ratio}%;
  }
`;

const ImageWrapper = styled.div`
  padding: ${props => props.padding};
  margin: ${props => props.margin};

  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const MultiContentWrapper = styled.div`
  margin: 0 0 45px 0;

  &:last-of-type {
    margin: 0;
  }

  @media (max-width: 767px) {
    margin: 15px 0;
  }
`;

const WorkContainer = styled.div`
  position: relative;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 45px;
  grid-auto-flow: dense;

  margin: 80px 0;

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
`;

const ProjectContainer = styled.div`
  grid-column-end: ${props => props.column};
  grid-column-start: ${props => props.columnStart};
  grid-row-end: ${props => props.gridEnd};

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 45px;

    @media (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
    }
  }

  .half-col {
    grid-column: span 2;
  }

  .middle-col {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  overflow: hidden;

  position: relative;

  @media (max-width: 767px) {
    grid-column-end: ${props => props.columnSpanMobile};
    grid-column-start: ${props => props.columnStartMobile};
    grid-row-end: ${props => props.gridEndMobile};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 100%;

  & .wrapper {
    flex: 100%;
    width: 100%;
  }

  & .publication-date {
    text-align: center;
    & p {
      margin: 0;
    }
  }
`;

const Text = styled.div`
  & p:first-of-type {
    margin-top: 0;
  }

  & p:last-of-type {
    margin-bottom: 0;
  }

  & strong {
    font-weight: 700;
  }

  @media (max-width: 767px) {
    & p {
      margin: 1em 0;
    }
  }
`;

const SingleTextColumn = styled.div`
  & > div {
    // column-count: 2;
    // column-gap: 45px;
    width: 100%;
  }

  //   @media (max-width: 767px) {
  //     column-count: 1;
  //   }
  // }
`;

const LongRead = ({ data }) => {
  let seoTitle = data.prismicLongRead.data.title.text
    ? data.prismicLongRead.data.title.text
    : 'Treatment Studio';

  let seoSubtitle = data.prismicLongRead.data.subtitle.text
    ? data.prismicLongRead.data.subtitle.text
    : '';

  let seoImage = data.prismicLongRead.data.hero_image.url
    ? data.prismicLongRead.data.hero_image.url
    : '';

  const additionalContent = data.prismicLongRead.data.body1.map(
    (content, index) => {
      if (content.slice_type === 'video') {
        return (
          <ProjectContainer key={`grid-item_${index}`} column={`span 4`}>
            <VideoWrapper
              display={1}
              ratio={
                (content.primary.video.height / content.primary.video.width) *
                100
              }
            >
              <LongReadVideo
                url={content.primary.video.html}
                id={`player_item_full_video_${index}`}
                audio={true}
              />
            </VideoWrapper>
          </ProjectContainer>
        );
      }

      if (content.slice_type === 'image') {
        return (
          <ProjectContainer key={`grid-item_${index}`} column={`span 4`}>
            <ImageWrapper>
              {content.primary.single_image.url !== null && (
                <CustomAspectRatio
                  image={content.primary.single_image.url}
                  alt={content.primary.single_image.alt}
                  width={content.primary.single_image.dimensions.width}
                  height={content.primary.single_image.dimensions.height}
                />
              )}
            </ImageWrapper>
          </ProjectContainer>
        );
      }

      if (content.slice_type === 'text') {
        return (
          <ProjectContainer key={`grid-item_${index}`} column={`span 4`}>
            <SingleTextColumn>
              <Text
                dangerouslySetInnerHTML={{
                  __html: content.primary.text.html,
                }}
              />
            </SingleTextColumn>
          </ProjectContainer>
        );
      }

      if (content.slice_type === 'image___video') {
        return (
          <ProjectContainer key={`grid-item_${index}`} column={`span 4`}>
            <div className="grid">
              {content.items.map((item, innerIndex) => {
                if (item.single_image.url !== null) {
                  return (
                    <div className="half-col image">
                      <MultiContentWrapper>
                        <CustomAspectRatio
                          image={item.single_image.url}
                          alt={item.single_image.alt}
                          width={item.single_image.dimensions.width}
                          height={item.single_image.dimensions.height}
                        />
                      </MultiContentWrapper>
                    </div>
                  );
                } else {
                  return (
                    <div className="half-col text">
                      <MultiContentWrapper>
                        <VideoWrapper
                          display={1}
                          ratio={
                            (item.single_video.height /
                              item.single_video.width) *
                            100
                          }
                        >
                          <LongReadVideo
                            url={item.single_video.html}
                            id={`player_item_image_video_${index}_${innerIndex}`}
                            audio={true}
                          />
                        </VideoWrapper>
                      </MultiContentWrapper>
                    </div>
                  );
                }
              })}
            </div>
          </ProjectContainer>
        );
      }

      if (
        content.slice_type === 'image_with_text' &&
        content.primary.text_position === 'Left'
      ) {
        return (
          <ProjectContainer key={`grid-item_${index}`} column={`span 4`}>
            <div className="grid">
              <div className="half-col text">
                <Text
                  dangerouslySetInnerHTML={{
                    __html: content.primary.text.html,
                  }}
                />
              </div>

              <div className="half-col image">
                {content.items.map((image, innerIndex) => (
                  <MultiContentWrapper
                    key={`grid_item_inner_index_${innerIndex}`}
                  >
                    <CustomAspectRatio
                      image={image.image_with_text.url}
                      alt={image.image_with_text.alt}
                      width={image.image_with_text.dimensions.width}
                      height={image.image_with_text.dimensions.height}
                    />
                  </MultiContentWrapper>
                ))}
              </div>
            </div>
          </ProjectContainer>
        );
      }

      if (
        content.slice_type === 'image_with_text' &&
        content.primary.text_position === 'Right'
      ) {
        return (
          <ProjectContainer key={`grid-item_${index}`} column={`span 4`}>
            <div className="grid">
              <div className="half-col image">
                {content.items.map((image, innerIndex) => (
                  <MultiContentWrapper
                    key={`grid_item_inner_index_${innerIndex}`}
                  >
                    <CustomAspectRatio
                      image={image.image_with_text.url}
                      alt={image.image_with_text.alt}
                      width={image.image_with_text.dimensions.width}
                      height={image.image_with_text.dimensions.height}
                    />
                  </MultiContentWrapper>
                ))}
              </div>

              <div className="half-col text">
                <Text
                  dangerouslySetInnerHTML={{
                    __html: content.primary.text.html,
                  }}
                />
              </div>
            </div>
          </ProjectContainer>
        );
      }
    }
  );

  return (
    <>
      <Helmet
        title={`${seoTitle} - ${seoSubtitle} - Treatment Studio`}
        meta={[
          {
            name: 'og:image',
            content: `${seoImage}`,
          },
          {
            name: 'og:image:secure_url',
            content: `${seoImage}`,
          },
          {
            name: 'og:image:width',
            content: `1200`,
          },
          {
            name: 'og:image:height',
            content: `630`,
          },
          {
            name: 'og:locale',
            content: `en`,
          },
          {
            name: 'twitter:title',
            content: `${seoTitle} - ${seoSubtitle} - Treatment Studio`,
          },
          {
            name: 'twitter:card',
            content: `summary_large_image`,
          },
          {
            name: 'twitter:image',
            content: `${seoImage}`,
          },
        ]}
      />

      <Page>
        <Grid>
          <div className="half-col">
            <TextWrapper>
              <div className="wrapper">
                <TitleContainer>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.prismicLongRead.data.title.html,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.prismicLongRead.data.subtitle.html,
                    }}
                  />
                  <h2>{data.prismicLongRead.data.year}</h2>
                </TitleContainer>

                <TextContainer
                  dangerouslySetInnerHTML={{
                    __html: data.prismicLongRead.data.author.html,
                  }}
                />

                <div className="publication-date">
                  <p>{data.prismicLongRead.first_publication_date}</p>
                </div>
              </div>
            </TextWrapper>
          </div>
          <div className="half-col">
            <img
              src={data.prismicLongRead.data.hero_image.url}
              alt={data.prismicLongRead.data.hero_image.alt}
            />
          </div>
        </Grid>

        <WorkContainer>{additionalContent}</WorkContainer>
      </Page>
    </>
  );
};

export default withPrismicPreview(LongRead);

export const query = graphql`
  query LongRead($uid: String!) {
    prismicLongRead(uid: { eq: $uid }) {
      _previewable
      first_publication_date(formatString: "MMMM YYYY")
      data {
        subtitle {
          html
          text
        }
        title {
          html
          text
        }
        year
        author {
          html
        }
        hero_image {
          url(imgixParams: { auto: "format" })
          alt
          dimensions {
            width
            height
          }
        }
        image {
          url(imgixParams: { auto: "format" })
          alt
          dimensions {
            width
            height
          }
        }
        body1 {
          ... on PrismicLongReadDataBody1Video {
            id
            slice_type
            primary {
              audio
              video {
                embed_url
                html
                width
                height
              }
            }
          }
          ... on PrismicLongReadDataBody1Image {
            id
            slice_type
            primary {
              single_image {
                alt
                url(imgixParams: { auto: "format" })
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicLongReadDataBody1ImageWithText {
            id
            slice_type
            primary {
              text {
                html
              }
              text_position
            }
            items {
              image_with_text {
                alt
                url(imgixParams: { auto: "format" })
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicLongReadDataBody1VideoWithText {
            id
            slice_type
            primary {
              text {
                html
              }
              text_position
            }
            items {
              video_with_text {
                width
                html
                height
                embed_url
              }
            }
          }
          ... on PrismicLongReadDataBody1Text {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicLongReadDataBody1ImageVideo {
            id
            slice_type
            items {
              single_image {
                alt
                url(imgixParams: { auto: "format" })
                dimensions {
                  width
                  height
                }
              }
              single_video {
                width
                html
                height
                embed_url
              }
            }
          }
        }
      }
    }
  }
`;
