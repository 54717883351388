import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import camelCase from 'lodash.camelcase';

// Utils
import { encodeForm } from '../utils/encode-form';

const FormSubmissionMessageContainer = styled.div`
  position: relative;
  max-width: 850px;
  width: 100%;
  background: #fff;

  margin: 100px 0 20px 0;
`;

const FormContainer = styled.div`
  position: relative;
  max-width: 850px;

  background: #fff;

  padding: 30px;
  margin: 0 auto;

  border: 1px solid #dadada;
  border-radius: 3px;

  @media (max-width: 600px) {
    padding: 15px;
  }

  & form {
    & .fields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;

      margin: 10px 0 20px 0;

      // media query 600px wide
      @media (max-width: 600px) {
        display: block;
        grid-template-columns: unset;
        grid-column-gap: unset;
      }
    }
  }

  & .form-field-container {
    margin: 0 0 2em 0;

    & label {
      font-size: 13px;
      margin: 0 0 5px 0;
      font-weight: 600;
      display: block;
    }

    & textarea {
      resize: none;
    }

    & label,
    & input,
    & select,
    & textarea {
      display: block;
    }

    & input,
    & select,
    & textarea {
      width: calc(100% - 10px);
      padding: 3px 5px;

      line-height: 26px;

      border-radius: 3px;
      border: 1px solid #dadada;
      /* transition: 300ms border-colors ease; */

      &:focus,
      &:focus-within,
      &:focus-visible {
        outline: none;
        border-color: #4c4c4c;
      }
    }

    &.textarea {
      grid-column: span 2;
    }

    & select {
      height: 34px;
      width: 100%;
    }
  }

  & .submit-button {
    border: 1px solid #dadada;
    padding: 3px 10px;
    line-height: 26px;

    border-radius: 3px;
    border: 1px solid #dadada;
    transition: 250ms all ease;

    &:hover {
      background: #dadada;
      /* color:  */
    }
  }
`;

export const ContactForm = ({
  jobTitle,
  submissionMessage,
  errorSubmissionMessage,
  datetime,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [formMessage, setFormMessage] = useState(errorSubmissionMessage.html);

  const data = useStaticQuery(graphql`
    query {
      prismicJobs {
        data {
          body {
            ... on PrismicJobsDataBodyTextArea {
              id
              slice_type
              primary {
                field_name
                spreadsheet_key
                required
              }
            }
            ... on PrismicJobsDataBodyTextField {
              id
              slice_type
              primary {
                field_name
                spreadsheet_key
                required
              }
            }
            ... on PrismicJobsDataBodyDropdownField {
              id
              slice_type
              primary {
                field_name
                spreadsheet_key
                required
              }
              items {
                field_option
              }
            }
          }
        }
      }
    }
  `);

  // Form Setup
  const { register, handleSubmit, errors, reset } = useForm({});

  const onSubmit = (data, e) => {
    let newData = {
      ...data,
      Datetime: datetime
    };
    e.preventDefault();

    fetch(`/.netlify/functions/write-to-google-sheets`, {
      method: 'POST',
      body: JSON.stringify({
        ...newData,
      }),
    });

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encodeForm({
        'form-name': `job-enquiry`,
        subject: `Job Application - ${jobTitle}`,
        ...newData,
      }),
    })
      .then(() => {
        setIsFormVisible(false);
        reset();
        setFormMessage(submissionMessage.html);
      })
      .catch(error => {
        console.log(error);
        setIsFormVisible(false);
        setFormMessage(errorSubmissionMessage.html);
      });
  };

  const fields = data.prismicJobs.data.body.map((field, index) => {
    if (field.slice_type === `text_field`) {
      return (
        <div className="form-field-container" key={`form_field_${index}`}>
          <label>
            {field.primary.field_name}
            {field.primary.required && ` *`}
          </label>
          <input
            type="text"
            name={field.primary.spreadsheet_key}
            ref={register({
              required: field.primary.required,
            })}
            aria-required={field.primary.required}
          />
        </div>
      );
    }

    if (field.slice_type === `text_area`) {
      return (
        <div
          className="form-field-container textarea"
          key={`form_field_${index}`}
        >
          <label>
            {field.primary.field_name}
            {field.primary.required && ` *`}
          </label>
          <textarea
            type="text"
            name={field.primary.spreadsheet_key}
            rows={
              field.primary.spreadsheet_key === `Link to site/portfolio` ? 1 : 4
            }
            draggable="false"
            ref={register({
              required: field.primary.required,
            })}
            aria-required={field.primary.required}
          />
        </div>
      );
    }

    if (field.slice_type === `dropdown_field`) {
      const options = field.items.map((option, index) => (
        <option value={option.field_option}>{option.field_option}</option>
      ));

      return (
        <div className="form-field-container" key={`form_field_${index}`}>
          <label>
            {field.primary.field_name}
            {field.primary.required && ` *`}
          </label>
          <select
            type="text"
            name={field.primary.spreadsheet_key}
            ref={register({
              required: field.primary.required,
            })}
            aria-required={field.primary.required}
          >
            {options}
          </select>
        </div>
      );
    }
  });



  return (
    <>
      {isFormVisible ? (
        <FormContainer>
          <form
            name="job-enquiry"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="fields">
              <input
                type="hidden"
                name="subject"
                data-remove-prefix
                value={`Job Enquiry – ${jobTitle}`}
              />

              <input
                type="hidden"
                name="Datetime"
                value={datetime}
              />

              {fields}
            </div>

            <button type="submit" className="submit-button">
              Submit Application
            </button>
          </form>
        </FormContainer>
      ) : (
        <FormSubmissionMessageContainer>
          <div dangerouslySetInnerHTML={{ __html: submissionMessage.html }} />
        </FormSubmissionMessageContainer>
      )}
    </>
  );
};
