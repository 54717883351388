import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Helmet } from 'react-helmet';

// Components
import '../components/styles/plyr.css';
import Instagram from '../components/icons/instagram';

const Homepage = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  height: 100%;
  width: 100%;

  overflow: hidden;
`;

const VideoWrapper = styled.div`
  position: relative;

  margin: 0 auto;

  max-width: calc((100vh + 28.12%) - 45px - 45px - 45px - 45px - 120px);
  width: 100%;
  width: min(100%, calc((100vh + 28.12%) - 45px - 45px - 45px - 45px - 120px));

  padding: 30px 0;
  align-self: center;

  & .player .vp-video-wrapper {
    background-color: #fff !important;
  }
`;

const Outer = styled.div`
  position: relative;
  margin: 0 auto;

  width: 100%;

  opacity: ${props => props.display};
  transition-delay: 500ms;
  transition: 500ms opacity ease;
`;

const Details = styled.div`
  position: relative;

  margin: 0 auto;

  max-width: calc((100vh + 28.12%) - 45px - 45px - 45px - 45px - 120px);
  width: 100%;
  width: min(100%, calc((100vh + 28.12%) - 45px - 45px - 45px - 45px - 120px));

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: flex-end;

  & > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  & p {
    margin: 0;
    width: 200px;
  }
`;

const Index = ({ data }) => {
  const [isVideoReady, setVideoIsReady] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = require('plyr'); // es 6 import will not work, will throw error window not defined

      const player = new Plyr('#player', {
        autoplay: true,
        muted: true,
        loop: { active: true },
        controls: ['mute'],
        hideControls: false,
        storage: { enabled: false, key: 'plyr' },
        vimeo: {
          byline: false,
          portrait: false,
          title: false,
          speed: false,
          controls: false,
          transparent: true,
          autoplay: true,
          muted: true,
        },
      });

      player.on('playing', event => {
        const instance = event.detail.plyr;
        instance.loading === false
          ? setVideoIsReady(true)
          : setVideoIsReady(false);
      });
    }
  }, []);

  return (
    <>
      <Helmet title={`Treatment Studio`} />
      <Homepage>
        <VideoWrapper>
          <Outer display={isVideoReady === true ? 1 : 0}>
            <div
              data-nosnippet
              className="plyr__video-embed"
              id="player"
              dangerouslySetInnerHTML={{
                __html: data.prismicHomepage.data.video_embed.html,
              }}
            />
          </Outer>
        </VideoWrapper>

        <Details>
          <div>
            <div
              data-nosnippet
              dangerouslySetInnerHTML={{
                __html: data.prismicHomepage.data.address.html,
              }}
            />

            <div
              data-nosnippet
              dangerouslySetInnerHTML={{
                __html: data.prismicHomepage.data.contact.html,
              }}
            />
          </div>
          <Instagram link={data.prismicHomepage.data.instagram.url} />
        </Details>
      </Homepage>
    </>
  );
};

export default withPrismicPreview(Index);

export const query = graphql`
  {
    prismicHomepage {
      _previewable
      data {
        address {
          html
        }
        contact {
          html
        }
        video_embed {
          html
          embed_url
        }
        instagram {
          url
        }
      }
    }
  }
`;
