import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;

  & ul {
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  & ul,
  & li {
    list-style: none;
  }

  & h3 {
    font-size: 25px;
  }
`;

const MenuLink = styled.li`
  font-size: 15px;

  color: ${props => (props.isActive ? '#000' : '#4c4c4c')};

  padding: 0 15px;

  transform: scale(${props => (props.isActive ? '1.3' : '1')});
  transform-origin: bottom;
  transition: 150ms transform linear;
  transform-style: preserve-3d;

  &:hover {
    transform: scale(1.3);
  }
`;

const DesktopMenu = ({ links, location, title, hideOnScroll }) => (
  <Menu>
    <nav>
      <ul>
        {links.map(link => (
          <MenuLink
            key={link.name}
            isActive={
              location.includes(link.name.toLowerCase()) ||
              (link.name === 'Work' && location.includes('project'))
            }
          >
            <Link to={link.link}>{link.name}</Link>
          </MenuLink>
        ))}
      </ul>
    </nav>
  </Menu>
);

export default DesktopMenu;
