import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  position: relative;

  width: 100%;
  height: 100%;

  object-fit: cover;
`;

const ThreeTwo = ({ image, alt, width, height, setAspectRatio, setSize }) => {
  const aspectRatio = setAspectRatio
    ? setAspectRatio
    : width > height
    ? `3:2`
    : `7:10`;

  const size = setSize === 'Large' ? 1000 : 500;

  return (
    <Image
      srcSet={`${image}&w=${size}&ar=${aspectRatio}&fit=crop&q=80? 1x,
              ${image}&w=${size}&ar=${aspectRatio}&fit=crop&dpr=2&q=80? 2x,
              ${image}&w=${1200}&ar=${aspectRatio}&fit=crop&dpr=3&q=100? 3x`}
      src={`${image}&w=${size}&ar=${aspectRatio}&fit=crop&q=80?`}
      alt={alt}
    />
  );
};

export default ThreeTwo;
