import React, { useEffect } from 'react';

export const LongReadVideo = ({ url, id, audio }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = require('plyr');

      const player = new Plyr(`#${id}`, {
        autoplay: false,
        muted: true,
        loop: { active: true },
        hideControls: false,
        storage: { enabled: false, key: 'plyr' },
      });
    }
  }, []);

  if (audio === true) {
    return (
      <div
        className="plyr__video-embed"
        id={id}
        data-plyr-config='{ "controls": ["mute", "fullscreen", "play-large"] }'
        dangerouslySetInnerHTML={{
          __html: url,
        }}
      />
    );
  } else {
    return (
      <div
        className="plyr__video-embed"
        id={id}
        data-plyr-config='{ "controls": ["fullscreen", "play-large"] }'
        dangerouslySetInnerHTML={{
          __html: url,
        }}
      />
    );
  }
};
