exports.linkResolver = doc => {
  // URL for a category type
  if (doc.type === 'homepage') {
    return `/`;
  }

  // URL for a product type
  if (doc.type === 'conta') {
    return `/contact/`;
  }

  // URL for a page type
  if (doc.type === 'about') {
    return `/about/`;
  }

  // URL for a jobs type
  if (doc.type === 'jobs') {
    return `/jobs/`;
  }

  // URL for a jobs type
  if (doc.type === 'press') {
    return `/press/`;
  }

  // URL for a page type
  if (doc.type === 'long_read') {
    return `/long-reads/${doc.uid}/`;
  }

  // URL for a job type
  if (doc.type === 'job') {
    return `/jobs/${doc.uid}/`;
  }

  // URL for a page type
  if (doc.type === 'project' || doc.link_type === 'Document') {
    return `/project/${doc.uid}/`;
  }

  // Backup for all other types
  return `/${doc.uid}/`;
};
