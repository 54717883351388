import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';
import Media from 'react-media';

// Components
import DesktopMenu from '../navigation/desktop-menu';
import Logo from '../icons/logo';
import MobileMenu from '../navigation/mobile-menu';

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  padding: 25px 45px;

  background-color: #fff;
  z-index: 200;

  transform: translateY(${props => (props.hideOnScroll ? '0' : '-100%')});
  transition: 450ms transform ease;

  @media (max-width: 768px) {
    padding: 25px 15px;
  }
`;

const LogoContainer = styled.div`
  width: 100%;

  text-align: center;

  padding: 0 0 20px 0;
`;

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      windowWidth: 768,
      prevScrollpos: 0,
      showMenu: true,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    // Get window dimensions on load
    if (typeof window !== `undefined`) {
      let windowWidth = window.innerWidth;
      this.setState({
        windowWidth: windowWidth,
      });
    }
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const showMenu =
      currentScrollPos <= 100 || prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      showMenu,
    });
  }

  render() {
    return (
      <HeaderWrapper hideOnScroll={this.state.showMenu}>
        <LogoContainer>
          <Link to={`/`}>
            <Logo />
          </Link>
        </LogoContainer>
        <Media
          queries={{ medium: '(max-width: 768px)' }}
          defaultMatches={{ medium: this.state.windowWidth === 768 }}
          render={() => (
            <MobileMenu
              links={this.props.data.site.siteMetadata.menuLinks}
              location={this.props.location.pathname}
            />
          )}
        />

        <Media
          queries={{ medium: '(min-width: 769px)' }}
          defaultMatches={{ medium: this.state.windowWidth === 769 }}
          render={() => (
            <DesktopMenu
              links={this.props.data.site.siteMetadata.menuLinks}
              location={this.props.location.pathname}
            />
          )}
        />
      </HeaderWrapper>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
);
