import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;

  & div {
    text-align: center;

    max-width: 600px;
    margin: 0 auto;
  }

  & ul {
    width: 100%;

    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    & li {
      padding-right: 10px;

      & a:after {
        content: ' / ';
        padding-left: 10px;
        display: inline-block;
      }

      &:last-of-type {
        padding: 0;

        & a:after {
          display: none;
        }
      }

      & a {
        text-decoration: underline;
      }
    }
  }
`;

const MapContainer = styled.div`
  width: 100%;
  max-width: 980px;
  height: 600px;

  margin: 2em auto;

  @media (max-width: 767px) {
    height: 300px;
  }
`;

const Contact = ({ data }) => {
  const links = data.prismicConta.data.links.map((link, index) => (
    <li key={`link_${index}`}>
      <div
        dangerouslySetInnerHTML={{
          __html: link.link.html,
        }}
      />
    </li>
  ));

  return (
    <>
      <Helmet title={`Contact - Treatment Studio`} />
      <ContactContainer>
        <div
          dangerouslySetInnerHTML={{
            __html: data.prismicConta.data.text.html,
          }}
        />
      </ContactContainer>

      <MapContainer>
        <a
          href={data.prismicConta.data.google_map_link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            srcSet={data.prismicConta.data.map_image.fluid.srcSetWebp}
            alt="Treatment Studio Map"
          />
        </a>
      </MapContainer>

      <ContactContainer>
        <ul>{links}</ul>
      </ContactContainer>
    </>
  );
};

export default withPrismicPreview(Contact);

export const query = graphql`
  {
    prismicConta {
      _previewable
      data {
        text {
          html
        }
        google_map_link {
          url
        }
        links {
          link {
            html
          }
        }
        map_image {
          fluid {
            srcSetWebp
          }
        }
      }
    }
  }
`;
