import React from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html{
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  body {
    color: #4c4c4c;
    background-color: #fff;


    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: 300;

    font-size: 15px;
    line-height: 18px;

    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;

    text-shadow: 1px 1px 1px rgba(0,0,0,.004);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  b,
  strong {
    font-weight: normal;
  }

  a,
  a:visited {
    text-decoration: none;
    color: inherit;
  }

  a:focus{
    outline: none;
  }

  a {
  }

  input[type="hidden"],
  textarea[type="hidden"],
  select[type="hidden"] {
    display: none;
  }

  em {
    font-family: 'Muli', sans-serif;
    font-style: italic;
  }

  h1, h2, h3, h4, h5, h6{
    margin: 0;
    font-weight: 300;
  }

  h1 {
    font-size: 22px;
  }

  h2{
    font-size: 20px;
  }

  h3{
  }


  button {
    cursor: pointer;
    padding: 0;
    margin: 0;
    appearance: none;
    border: 0;
    background-color: transparent;

    &:focus {
      outline: 0;
    }
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #7f7f7f;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #7f7f7f;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #7f7f7f;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #7f7f7f;
  }

  img {
    width: 100%;
    display: block;
  }



/*--------------------------------------------------------------
  ## React Modal
--------------------------------------------------------------*/
.ReactModalPortal{
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 100;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModal__Content.ReactModal__Content--after-open.job-enquiry-form-modal{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ReactModalPortal-job-enquiry-form-modal {
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 10000;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModalPortal-job-enquiry-form-modal .ReactModal__Overlay {
  background-color: #f5eadf !important;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content{
  width: 100%;
  height: 100%;

  inset: unset !important;
  background: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}


`;

export default GlobalStyle;
