import React, { useEffect } from 'react';
import styled from 'styled-components';

// Components
import Header from './header';

const Main = styled.main`
  padding: 120px 45px 45px 45px;

  flex: 1;

  @media (max-width: 767px) {
    padding: 120px 15px 15px 15px;
  }
`;

const App = ({ location, data, children }) => {
  useEffect(() => {
    console.log(
      '%cDevelopment: Kieran Startup (https://www.kieranstartup.co.uk)',
      'color:red; font-family:Helvetica Neue;  font-size: 16px'
    );
  }, []);

  return (
    <>
      <Header location={location} />
      <Main>{children}</Main>
    </>
  );
};

export default App;
