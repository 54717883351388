import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const AboutContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;

  & div {
    text-align: center;

    max-width: 600px;
    margin: ${props => props.margin};

    & p:first-of-type {
      margin-top: 0;
    }
  }
`;

const About = ({ data }) => {
  return (
    <>
      <Helmet title={`About - Treatment Studio`} />
      <AboutContainer margin={`70px auto 0 auto`}>
        <div
          dangerouslySetInnerHTML={{
            __html: data.prismicAbout.data.text.html,
          }}
        />
      </AboutContainer>

      <AboutContainer margin={`55px auto 0 auto`}>
        <div
          dangerouslySetInnerHTML={{
            __html: data.prismicAbout.data.additional_text.html,
          }}
        />
      </AboutContainer>
    </>
  );
};

export default withPrismicPreview(About);

export const query = graphql`
  {
    prismicAbout {
      _previewable
      data {
        text {
          html
        }
        additional_text {
          html
        }
      }
    }
  }
`;
