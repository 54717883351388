import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const JobsContainer = styled.div`
  position: relative;

  padding: 60px 0 0 0;
  margin: 0 auto;
  max-width: 900px;

  /* @media (min-width: 1500px) {
    max-width: 1400px;
  } */

  /* @media (max-width: 1499px) { */
  /* max-width: 1050px; */
  /* } */

  @media (max-width: 767px) {
    padding: 15px 0 0 0;

    /* grid-template-columns: 1fr 1fr; */
    grid-gap: 15px;
  }

  & .grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 45px;

    @media (max-width: 767px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
    }
  }

  & .jobs-container {
    margin: 40px 0 0 0;
    padding: 0;
    list-style: none;

    & li {
      margin: 0 0 2em 0;
      list-style: none;

      line-height: 170%;

      & h1 {
        color: #000;
      }

      & h2 {
        font-size: 18px;
        /* color: #9c9c9c; */
      }
    }
  }
`;

const Jobs = ({ data }) => {
  const allJobs = data.prismicJobs.data.jobs
    .filter(job => job.job.document !== null)
    .map((job, index) => (
      <li key={`single_job_${index}`}>
        <Link to={job.job.document.url}>
          <div
            dangerouslySetInnerHTML={{
              __html: job.job.document.data.job_title.html,
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: job.job.document.data.job_subtitle.html,
            }}
          />
        </Link>
      </li>
    ));

  return (
    <>
      <Helmet title={`Jobs - Treatment Studio`} />
      <JobsContainer margin={`70px auto 0 auto`}>
        <div className="grid">
          <div className="column left">
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicJobs.data.text.html,
              }}
            />
          </div>
          <div className="column right">
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicJobs.data.contact_text.html,
              }}
            />
          </div>
        </div>

        <div className="grid">
          <div className="column left">
            <ol className="jobs-container">{allJobs}</ol>
          </div>
          <div className="column right">
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicJobs.data.address_text.html,
              }}
            />
          </div>
        </div>
      </JobsContainer>
    </>
  );
};

export default withPrismicPreview(Jobs);

export const query = graphql`
  {
    prismicJobs {
      _previewable
      data {
        text {
          html
        }
        contact_text {
          html
        }
        address_text {
          html
        }
        jobs {
          job {
            document {
              ... on PrismicJob {
                id
                _previewable
                url
                data {
                  job_title {
                    html
                  }
                  job_subtitle {
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
