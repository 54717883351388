import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;

  & ul {
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;
  }

  & ul,
  & li {
    list-style: none;
  }

  & li {
    padding-right: 15px;
    &:last-of-type {
      padding-right: 0;
    }

    @media (max-width: 320px) {
      padding: 0 7.5px;
    }
  }

  & h3 {
    font-size: 25px;
  }
`;

const MenuLink = styled.li`
  font-size: ${props => (props.isActive ? '18px' : '14px')};
  color: ${props => (props.isActive ? '#000' : '#4c4c4c')};
`;

const MobileMenu = ({ links, location, title, hideOnScroll }) => (
  <Menu>
    <nav>
      <ul>
        {links.map(link => (
          <MenuLink
            key={link.name}
            isActive={
              location.includes(link.name.toLowerCase()) ||
              (link.name === 'Work' && location.includes('project'))
            }
          >
            <Link to={link.link}>{link.name}</Link>
          </MenuLink>
        ))}
      </ul>
    </nav>
  </Menu>
);

export default MobileMenu;
