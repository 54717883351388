import React from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import './carousel.css';
import '../styles/plyr.css';

// Components
import CustomAspectRatio from './custom-aspect-ratio';

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;

  padding: 0;

  & .image-gallery,
  & .carousel.carousel-slider,
  & .slider-wrapper,
  & .slider,
  & .slide {
    height: 100%;

    max-width: 1400px;
    max-height: calc(100vh - 180px);
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 25%;
  }
`;

const ImageContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;

  & img {
    object-fit: contain;

    max-width: 1400px;
    max-height: calc(100vh - 180px);
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  position: relative;

  // margin: ${props => props.margin};
  // padding-top: ${props => props.ratio}%;

  // & .react-player {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  // }
`;

class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      allSlides: [],
      activeSlide: null,
      totalSlides: 1,
    };
  }

  componentDidMount(props) {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = require('plyr');

      const player = new Plyr('#player', {
        autoplay: true,
        muted: true,
        loop: { active: true },
        controls: ['mute', 'fullscreen'],
        hideControls: false,
        storage: { enabled: false, key: 'plyr' },
      });
    }
  }

  updateCurrentSlide = index => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.setState({
        currentSlide: index,
      });
    }
  };

  render() {
    const galleryContent = this.props.content.map((content, index) => {
      if (content.slice_type === 'image') {
        return (
          <ImageContainer key={`gallery_image_${index}`} className="image">
            {content.primary.single_image.url !== null && (
              <CustomAspectRatio
                image={content.primary.single_image.url}
                alt={content.primary.single_image.alt}
                lazy={false}
                size={1200}
                objectFit={`cover`}
                width={content.primary.single_image.dimensions.width}
                height={content.primary.single_image.dimensions.height}
              />
            )}
          </ImageContainer>
        );
      }

      if (content.slice_type === 'video') {
        return (
          <VideoWrapper
            key={`gallery_image_${index}`}
            className="video"
            ratio={
              (content.primary.video.height / content.primary.video.width) * 100
            }
          >
            <div
              className="plyr__video-embed"
              id="player"
              dangerouslySetInnerHTML={{
                __html: content.primary.video.html,
              }}
            />
          </VideoWrapper>
        );
      }
    });

    return (
      <GalleryContainer>
        <Carousel
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          infiniteLoop={true}
          useKeyboardArrows={true}
          className="image-gallery"
          selectedItem={this.state.currentSlide}
          onChange={this.updateCurrentSlide}
          transitionTime={1000}
        >
          {galleryContent}
        </Carousel>
      </GalleryContainer>
    );
  }
}

export default Gallery;
