import React from 'react';
import { Location } from '@reach/router';
import styled from 'styled-components';
import { Normalize } from 'styled-normalize';
import { Helmet } from 'react-helmet';

import GlobalStyles from '../styles/globalStyles';
import App from './app';
import { DefaultSEO } from './default-seo';

const Container = styled.div`
  position: relative;

  display: flex;
  min-height: 100vh;
  flex-direction: column;

  width: 100%;

  overflow: ${props => (props.location === '/' ? 'hidden' : '')};
  height: ${props => (props.location === '/' ? 'calc(100vh - 36px)' : 'auto')};
`;

export const Layout = ({ children }) => (
  <Location>
    {({ location }) => {
      return (
        <>
          <Helmet>
            <link
              href="https://fonts.googleapis.com/css2?family=Muli:ital,wght@1,300&display=swap"
              rel="stylesheet"
            />
          </Helmet>
          <Container location={location.pathname}>
            <Normalize />
            <GlobalStyles />
            <DefaultSEO />
            <App location={location} children={children} />
          </Container>
        </>
      );
    }}
  </Location>
);

export default Layout;
