import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import sal from 'sal.js';
import { isMobile } from 'react-device-detect';

// Styles
import '../components/styles/sal.css';
import '../components/styles/plyr.css';

// Components
import Gallery from '../components/images/gallery';
import ThreeTwo from '../components/images/three-two';
import CustomAspectRatio from '../components/images/custom-aspect-ratio';
import VideoPoster from '../components/images/video-poster';
import AdditionalVideo from '../components/projects/video';

const Page = styled.div`
  margin: 60px auto 0 auto;

  @media (min-width: 1500px) {
    max-width: 1200px;
  }

  @media (max-width: 1499px) {
    max-width: 1050px;
  }

  @media (max-width: 767px) {
    margin: 15px auto 0 auto;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const TitleContainer = styled.div`
  width: 100%;
  margin: 10px 0 0 0;

  line-height: 1.25;
`;

const TextContainer = styled.div`
  max-width: 370px;
  margin: 45px 0 0 0;

  & > p:first-of-type {
    margin-top: 0;
  }

  & > p:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 960px) {
    max-width: 500px;
    margin: 45px 0;
  }
`;

const CreditsContainer = styled.div`
  width: calc(100% - 370px - 45px);

  margin: 45px 0 0 0;
  padding: 0 0 0 45px;

  column-count: 3;
  column-gap: 30px;

  @media (max-width: 1200px) {
    padding: 0 0 0 30px;
  }

  @media (max-width: 960px) {
    width: 100%;

    column-count: 2;
    column-gap: 15px;

    margin: 0 0 45px 0;
    padding: 0;
  }
`;

const Credit = styled.div`
  page-break-inside: avoid;
  break-inside: avoid;

  margin-bottom: 1em;

  & > p {
    margin: 0;

    color: #4c4c4c;
  }

  & > div > p {
    margin: 0;
    word-break: break-word;
  }
`;

const RelatedTitle = styled.h4`
  width: 100%;

  font-size: 22px;
  color: #4c4c4c;

  margin: 10px 0 35px 0;
`;

const Line = styled.hr`
  width: 100%;

  border: 0;
  border-top: 1px solid #4c4c4c;
  border-style: outset;
`;

const RelatedRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 45px;
  grid-auto-flow: dense;

  width: 100%;

  @media (max-width: 1200px) {
    grid-gap: 30px;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }

  // & > div {
  //   margin: 0 0 15px 0;
  // }
`;

const Related = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  & .slide-up-animation {
    width: 100%;
    height: 100%;
  }

  & .inner-container {
    overflow: hidden;

    position: relative;

    width: 100%;
    height: 100%;
  }

  & .title {
    margin: 0;

    width: calc(100% - 20px);
  }

  &:hover .title {
    opacity: 1;
  }

  .three-two {
    position: relative;
    overflow: hidden;
  }

  .three-two:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 66.667%;
  }

  .three-two > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    margin: 0;
  }

  & img {
    z-index: 0;
    transition: 250ms transform ease;
  }

  &:hover img {
    transform: scale(1.1);
  }
`;

const Title = styled.div`
  position: absolute;
  z-index: 100;

  opacity: ${props => props.opacity};

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;

  top: 0;
  left: 0;

  padding: 10px;
  color: #fff;

  transition: 250ms opacity ease;
  transform: translateY(${props => props.translateY});

  & div {
    width: calc(100% - 25px);
    line-height: 1.25;
  }

  & h1 {
    font-size: ${props => props.h2Size};
  }

  & h2 {
    font-size: ${props => props.h3Size};
  }

  & button {
    font-size: 30px;
    color: #fff;

    width: 18px;
    height: 18px;
    line-height: 18px;

    display: ${props => props.buttonDisplay};
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  position: relative;

  opacity: ${props => props.display};
  transition: 500ms opacity ease;

  & .plyr__video-embed {
    padding-bottom: ${props => props.ratio}%;
  }

  // & .plyr__video-embed__container {
  //   transform: translateY(-${props => props.translateY}%) !important;
  // }
`;

const ImageWrapper = styled.div`
  padding: ${props => props.padding};
  margin: ${props => props.margin};

  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const WorkContainer = styled.div`
  position: relative;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 45px;
  grid-auto-flow: dense;

  margin: 80px 0;

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
`;

const ProjectContainer = styled.div`
  grid-column-end: ${props => props.column};
  grid-column-start: ${props => props.columnStart};
  grid-row-end: ${props => props.gridEnd};

  overflow: hidden;

  position: relative;

  @media (max-width: 767px) {
    grid-column-end: ${props => props.columnSpanMobile};
    grid-column-start: ${props => props.columnStartMobile};
    grid-row-end: ${props => props.gridEndMobile};
  }
`;

const VideoContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
`;

const Project = ({ data }) => {
  const [isVideoReady, setVideoIsReady] = useState(false);
  const [videoInstance, setVideoInstance] = useState(null);
  const [activeAdditionalVideo, setActiveAdditionalVideo] = useState(null);

  let seoTitle = data.prismicProject.data.title.text
    ? data.prismicProject.data.title.text
    : 'Treatment Studio';

  let seoSubtitle = data.prismicProject.data.subtitle.text
    ? data.prismicProject.data.subtitle.text
    : '';

  let seoDescription = data.prismicProject.data.text.text
    ? `${data.prismicProject.data.text.text.substring(0, 99)}...`
    : '';

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      sal({
        threshold: 0.2,
        once: true,
      });

      const Plyr = require('plyr');

      const player = new Plyr('#player', {
        autoplay: true,
        muted: true,
        loop: {
          active: true,
        },
        hideControls: false,
        storage: { enabled: false, key: 'plyr' },
        vimeo: {
          byline: false,
          portrait: false,
          title: false,
          speed: true,
          transparent: true,
          controls: false,
          autopause: true,
          referrerPolicy: 'strict-origin-when-cross-origin',
        },
      });

      player.on('ready', event => {
        const instance = event.detail.plyr;
        setVideoInstance(instance);
      });

      player.on('playing', event => {
        const instance = event.detail.plyr;
        instance.loading === false
          ? setVideoIsReady(true)
          : setVideoIsReady(false);
        setVideoInstance(instance);
      });
    }
  }, []);

  const credits = data.prismicProject.data.credits.map((credit, index) => (
    <Credit key={`credit_${index}`}>
      <p>{credit.credit_title}</p>
      <div
        dangerouslySetInnerHTML={{
          __html: credit.credit_text.html,
        }}
      />
    </Credit>
  ));

  const additionalContent = data.prismicProject.data.body1.map(
    (content, index) => {
      let columnSpan;
      let columnStart;
      let gridEnd;

      let columnSpanMobile;
      let columnStartMobile;
      let gridEndMobile;

      let padding;

      if (content.primary.additional_content_size === 'Small') {
        columnSpan = `span 2`;
        gridEnd = `span 1`;
        columnSpanMobile = `span 1`;
        gridEndMobile = `span 1`;
      }

      if (content.primary.additional_content_size === 'Portrait') {
        columnSpan = `span 2`;
        gridEnd = `span 2`;

        columnSpanMobile = `span 2`;
        gridEndMobile = `span 2`;
      }

      if (content.primary.additional_content_size === 'Large') {
        columnSpan = `span 4`;
        gridEnd = `span 2`;

        columnSpanMobile = `span 2`;
        gridEndMobile = `span 2`;
      }

      if (content.primary.additional_content_size === 'Full Width') {
        columnSpan = `span 4`;
        columnSpanMobile = `span 2`;
      }

      if (content.slice_type === 'video') {
      }

      return (
        <ProjectContainer
          column={columnSpan}
          columnStart={columnStart}
          gridEnd={gridEnd}
          columnSpanMobile={columnSpanMobile}
          columnStartMobile={columnStartMobile}
          gridEndMobile={gridEndMobile}
          key={`grid-item_${index}`}
        >
          <>
            {content.slice_type === 'video' && (
              <VideoWrapper
                display={1}
                padding={padding}
                // translateY={
                //   (content.primary.video.height / content.primary.video.width) *
                //     100 -
                //   1.3
                // }
                ratio={
                  (content.primary.video.height / content.primary.video.width) *
                  100
                }
              >
                <AdditionalVideo
                  url={content.primary.video.html}
                  id={`additional_video_${index}`}
                  audio={content.primary.audio}
                  activeAdditionalVideo={activeAdditionalVideo}
                  setActiveAdditionalVideo={setActiveAdditionalVideo}
                />
              </VideoWrapper>
            )}

            {content.slice_type === 'image' && (
              <ImageWrapper>
                {content.primary.single_image.url !== null && (
                  <CustomAspectRatio
                    image={content.primary.single_image.url}
                    setSize={content.primary.additional_content_size}
                    alt={content.primary.single_image.alt}
                    width={content.primary.single_image.dimensions.width}
                    height={content.primary.single_image.dimensions.height}
                  />
                )}
              </ImageWrapper>
            )}
          </>
        </ProjectContainer>
      );
    }
  );

  const relatedProjects = data.prismicProject.data.related_projects
    .filter(
      project => project.project !== null && project.project.document !== null
    )
    .map((project, index) => {
      return (
        <Related key={`project_${project?.project?.document?.uid}_${index}`}>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-quad"
            className="slide-up-animation"
          >
            <Link to={`/project/${project?.project?.document?.uid}`}>
              <div className="inner-container">
                <Title
                  className="title"
                  opacity={isMobile === true ? 1 : 0}
                  h2Size={isMobile === true ? `14px` : `22px`}
                  h3Size={isMobile === true ? `12px` : `15px`}
                  buttonDisplay={isMobile === true ? `none` : `block`}
                  translateY={isMobile === true ? `0` : `20px`}
                >
                  <div>
                    {/* <h2>{project.project.document.data.title.text}</h2> */}
                    {/* <h3>{project.project.document.data.subtitle.text}</h3> */}

                    <div
                      dangerouslySetInnerHTML={{
                        __html: project?.project?.document?.data.title.html,
                      }}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: project?.project?.document?.data.subtitle.html,
                      }}
                    />
                  </div>
                  <button>+</button>
                </Title>
                <div className="three-two">
                  <div className="content">
                    {project?.project?.document?.data.image.url !== null && (
                      <ThreeTwo
                        image={project?.project?.document?.data.image.url}
                        setSize={project?.project?.document?.data.image_size}
                        alt={project?.project?.document?.data.image.alt}
                        width={
                          project?.project?.document?.data.image.dimensions
                            .width
                        }
                        height={
                          project?.project?.document?.data.image.dimensions
                            .height
                        }
                        setAspectRatio={`3:2`}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Related>
      );
    });

  const content = data.prismicProject.data.body.map((content, index) => {
    if (content.slice_type === 'image') {
      return (
        <div key={`image_${index}`}>
          {content.primary.single_image.url !== null && (
            <CustomAspectRatio
              image={content.primary.single_image.url}
              setSize={content.primary.additional_content_size}
              alt={content.primary.single_image.alt}
              width={content.primary.single_image.dimensions.width}
              height={content.primary.single_image.dimensions.height}
            />
          )}
        </div>
      );
    }

    if (content.slice_type === 'video') {
      return (
        <VideoWrapper
          display={isVideoReady === true ? 1 : 0}
          key={`video_${index}`}
          ratio={
            (content.primary.video.height / content.primary.video.width) * 100
          }
          // translateY={
          //   (content.primary.video.height / content.primary.video.width) * 100 -
          //   1.3
          // }
        >
          {content.primary.audio === true ? (
            <div
              className="plyr__video-embed"
              id="player"
              data-plyr-config='{ "controls": ["mute", "fullscreen"] }'
              dangerouslySetInnerHTML={{
                __html: content.primary.video.html,
              }}
            />
          ) : (
            <div
              className="plyr__video-embed"
              id="player"
              data-plyr-config='{ "controls": ["fullscreen"] }'
              dangerouslySetInnerHTML={{
                __html: content.primary.video.html,
              }}
            />
          )}
        </VideoWrapper>
      );
    }
  });

  return (
    <>
      <Helmet
        title={`${seoTitle} - ${seoSubtitle} - Treatment Studio`}
        meta={[
          {
            name: 'description',
            content: `${seoDescription}`,
          },
          // {
          //   name: 'og:image',
          //   content: `${seoImage}`,
          // },
          // {
          //   name: 'og:image:secure_url',
          //   content: `${seoImage}`,
          // },
          {
            name: 'og:description',
            content: `${seoDescription}`,
          },
          {
            name: 'og:image:width',
            content: `1200`,
          },
          {
            name: 'og:image:height',
            content: `630`,
          },
          {
            name: 'og:locale',
            content: `en`,
          },
          {
            name: 'twitter:title',
            content: `${seoTitle} – ${seoSubtitle}`,
          },
          {
            name: 'twitter:description',
            content: `${seoDescription}`,
          },
          {
            name: 'twitter:card',
            content: `summary_large_image`,
          },
          // {
          //   name: 'twitter:image',
          //   content: `${seoImage}`,
          // },
        ]}
      />

      <Page>
        <>
          {data.prismicProject.data.body.length === 1 &&
          data.prismicProject.data.body[0].slice_type === 'video' ? (
            <VideoContainer>
              {isVideoReady === false && videoInstance !== null && (
                <VideoPoster
                  video={
                    data.prismicProject.data.body[0].primary.video.embed_url
                  }
                  width={data.prismicProject.data.body[0].primary.video.width}
                  height={data.prismicProject.data.body[0].primary.video.height}
                />
              )}

              {content}
            </VideoContainer>
          ) : (
            <>{data.prismicProject.data.body.length === 1 && content}</>
          )}

          {data.prismicProject.data.body.length > 1 && (
            <Gallery content={data.prismicProject.data.body} />
          )}
        </>

        <Row>
          <TitleContainer>
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicProject.data.title.html,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicProject.data.subtitle.html,
              }}
            />
          </TitleContainer>

          <TextContainer
            dangerouslySetInnerHTML={{
              __html: data.prismicProject.data.text.html,
            }}
          />

          <CreditsContainer>{credits}</CreditsContainer>
        </Row>

        <WorkContainer>{additionalContent}</WorkContainer>

        {relatedProjects.length >= 1 && (
          <>
            <Line />
            <RelatedTitle>Related projects</RelatedTitle>
            <RelatedRow>{relatedProjects}</RelatedRow>
          </>
        )}
      </Page>
    </>
  );
};

export default withPrismicPreview(Project);

export const query = graphql`
  query Projects($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      _previewable
      prismicId
      data {
        title {
          html
          text
        }
        subtitle {
          html
          text
        }
        text {
          html
          text
        }
        credits {
          credit_title
          credit_text {
            html
          }
        }
        body {
          ... on PrismicProjectDataBodyImage {
            id
            slice_type
            primary {
              single_image {
                url(imgixParams: { auto: "format" })
                dimensions {
                  height
                  width
                }
                alt
              }
            }
          }
          ... on PrismicProjectDataBodyVideo {
            id
            slice_type
            primary {
              audio
              video {
                embed_url
                html
                width
                height
              }
            }
          }
        }
        related_projects {
          project {
            document {
              ... on PrismicProject {
                id
                uid
                data {
                  image {
                    url(imgixParams: { auto: "format" })
                    dimensions {
                      height
                      width
                    }
                    alt
                  }
                  title {
                    text
                    html
                  }
                  subtitle {
                    text
                    html
                  }
                }
              }
            }
          }
        }
        body1 {
          ... on PrismicProjectDataBody1Video {
            id
            slice_type
            primary {
              additional_content_size
              audio
              video {
                embed_url
                width
                height
                html
              }
            }
          }
          ... on PrismicProjectDataBody1Image {
            id
            slice_type
            primary {
              additional_content_size
              single_image {
                url(imgixParams: { auto: "format" })
                alt
                dimensions {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;
