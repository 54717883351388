import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

export const DefaultSEO = () => {
  const data = useStaticQuery(graphql`
    {
      prismicHomepage {
        data {
          seo_description
          seo_image {
            url
          }
        }
      }
    }
  `);

  return (
    <Helmet
      meta={[
        {
          name: 'description',
          content: `${data.prismicHomepage.data.seo_description}`,
        },
        {
          name: 'og:image',
          content: `${data.prismicHomepage.data.seo_image.url}`,
        },
        {
          name: 'og:image:secure_url',
          content: `${data.prismicHomepage.data.seo_image.url}`,
        },
        {
          name: 'og:description',
          content: `${data.prismicHomepage.data.seo_description}`,
        },
        {
          name: 'og:image:width',
          content: `1200`,
        },
        {
          name: 'og:image:height',
          content: `630`,
        },
        {
          name: 'og:locale',
          content: `en`,
        },
        {
          name: 'twitter:title',
          content: `Maison Artefact`,
        },
        {
          name: 'twitter:description',
          content: `${data.prismicHomepage.data.seo_description}`,
        },
        {
          name: 'twitter:card',
          content: `summary_large_image`,
        },
        {
          name: 'twitter:image',
          content: `${data.prismicHomepage.data.seo_image.url}`,
        },
      ]}
    />
  );
};
