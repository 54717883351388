import React, { useEffect, useState } from 'react';

const AdditionalVideo = ({
  url,
  id,
  audio,
  activeAdditionalVideo,
  setActiveAdditionalVideo,
}) => {
  const [videoPlayer, setVideoPlayer] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = require('plyr');

      const player = new Plyr(`#${id}`, {
        autoplay: false,
        muted: true,
        loop: { active: true },
        hideControls: false,
        clickToPlay: true,
        storage: { enabled: false, key: 'plyr' },
        vimeo: {
          byline: false,
          portrait: false,
          title: false,
          speed: true,
          transparent: true,
          controls: false,
          autopause: true,
        },
      });

      player.on('play', event => {
        setActiveAdditionalVideo(id);
      });

      setVideoPlayer(player);
    }
  }, []);

  useEffect(() => {
    if (activeAdditionalVideo !== null && videoPlayer !== null) {
      if (activeAdditionalVideo !== id) {
        videoPlayer.pause();
      }
    }
  }, [activeAdditionalVideo, videoPlayer]);

  if (audio === true) {
    return (
      <div
        className="plyr__video-embed"
        id={id}
        data-plyr-config='{ "controls": ["mute", "fullscreen", "play-large", "play"] }'
        dangerouslySetInnerHTML={{
          __html: url,
        }}
      />
    );
  } else {
    return (
      <div
        className="plyr__video-embed"
        id={id}
        data-plyr-config='{ "controls": ["fullscreen", "play-large", "play"] }'
        dangerouslySetInnerHTML={{
          __html: url,
        }}
      />
    );
  }
};

export default AdditionalVideo;
