import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  width: 240px;

  @media (max-width: 600px) {
    width: 180px;
  }
`;

const Logo = () => (
  <Icon
    id="Logo"
    dataName="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1920 254"
  >
    <g>
      <path d="M195.52,213.97V62.72h-47.5V38.97h120v23.75h-47.5v151.25H195.52z" />
      <path
        d="M360.77,131.47v82.5h-25v-175h61.25c16,0,26,3.75,34,10.25c10.5,8.5,17.25,21.5,17.25,36s-6.75,27.5-17.25,36
		c-5.5,4.5-11.75,7.75-20.5,9.25l62.75,83.5h-30.25l-62-82.5H360.77z M395.77,107.72c11.25,0,17-2.5,21-6.5s6.5-9.75,6.5-16
		s-2.5-12-6.5-16s-9.75-6.5-21-6.5h-35v45H395.77z"
      />
      <path d="M526.27,213.97v-175h100v23.75h-75v45h57.5v23.75h-57.5v58.75h77.5v23.75H526.27z" />
      <path
        d="M682.27,213.97l72.5-175h17.5l72.5,175h-26l-18.5-45h-73.75l-18.5,45H682.27z M736.52,145.22h54l-27.25-65.25
		L736.52,145.22z"
      />
      <path d="M906.51,213.97V62.72h-47.5V38.97h120v23.75h-47.5v151.25H906.51z" />
      <path d="M1043.01,213.97v-175h17.5l66.75,98l65.75-98h17.5v175h-24.5V91.47l-56.75,83.75h-5l-56.75-83.5v122.25H1043.01z" />
      <path d="M1278.74,213.97v-175h100v23.75h-75v45h57.5v23.75h-57.5v58.75h77.5v23.75H1278.74z" />
      <path d="M1445.98,213.97v-175h17.5l105,128.5V38.97h25v175h-17.5l-105-128.5v128.5H1445.98z" />
      <path d="M1706.48,213.97V62.72h-47.5V38.97h120v23.75h-47.5v151.25H1706.48z" />
    </g>
    <g>
      <path
        d="M83.13,236l-1.11-1.55c-22.73-31.78-34.75-69.13-34.75-108.02c0-38.88,12.02-76.23,34.75-108.01l1.11-1.55l21.08,14.51
		l-1.14,1.6c-19.25,27.09-29.85,60.28-29.85,93.45s10.6,66.36,29.85,93.45l1.14,1.6L83.13,236z"
      />
    </g>
    <g>
      <path
        d="M1842.5,236l-19.69-13.56l0.58-0.81c19.57-27.54,30.35-61.29,30.35-95.02c0-33.73-10.78-67.47-30.35-95.02l-0.58-0.81
		l19.69-13.56l0.56,0.78c22.86,31.95,34.94,69.51,34.94,108.6c0,39.1-12.08,76.65-34.94,108.6L1842.5,236z"
      />
    </g>
  </Icon>
);

export default Logo;
